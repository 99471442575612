import { Link } from "gatsby-plugin-react-i18next"
import { TypographyRelatedCardHeading } from "../Styling/Style"
import CommonCard from "../Cards/CommonCard"

/**
 * Use this type to create props to be passed to @RelatedSection
 * @param title Head section title
 * @param path Partial url between FQDN and Slug
 * @param data the Props containing related data.
 * @param backgroupnd Color of the section
 */
export type RelatedSectionProps = {
  title: string
  path?: string
  data: []
  background?: string
  headerUrl?: string
}

/**
 * THis will create the related section that will paint cards.
 * TOOD: Show/Hide buttons at start/end of array
 * @param props data as defined by @RelatedSectionProps
 */
export const MSRelatedSection = (props: RelatedSectionProps) => {
  // Props
  const bg = props.background || " bg-bgokologisk-500"
  const sectionClassName = "relative " + bg

  return (
    <section className={sectionClassName}>
      {props.headerUrl ? (
        <Link to={props.headerUrl}>
          <h3 className={TypographyRelatedCardHeading}>{props.title}</h3>
        </Link>
      ) : (
        <h3 className={TypographyRelatedCardHeading}>{props.title}</h3>
      )}
      <div className="flex flex-wrap content-evenly flex-row justify-center ">
        {props.data.map((item, counter) => {
          const readMoreProps = {
            data: item,
            url: props.path,
          }
          return <CommonCard key={counter} {...readMoreProps} />
        })}
      </div>
      {props.headerUrl ? (
        <div className="flex justify-center object-bottom  pb-10 relative">
          <Link to={props.headerUrl}>
            <button
              type="button"
              className="bg-transparent text-sort-200 hover:bg-tomat-500 hover:text-white  text-button18 hover:underline py-2 px-8 border border-tomat-500 hover:border-transparent rounded-full"
              id="user-menu"
              aria-haspopup="true"
            >
              Se flere artikler
            </button>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </section>
  )
}
