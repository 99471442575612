import { graphql, useStaticQuery } from "gatsby"
import { SortByDate } from "../components/Common/DateConverter"
import { RelatedSectionProps, MSRelatedSection } from "../components/Sections/MSRelatedSection"

/**
 * @description Related articles Min Side
 * @param param0
 */

const RelatedContentFrontMS = () => {
  // Sort all related articles from new to old. Let GH override with createdAtModified

  const data = useStaticQuery(graphql`
    {
      allContentfulArticle(
        filter: { public: { eq: false }, node_locale: { eq: "nb-NO" }, articleType: { eq: false } }
      ) {
        nodes {
          id
          public
          contentful_id
          title
          subtitle
          slug
          order
          node_locale
          metadataDescription
          createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
          createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
          updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
          category {
            id
            slug
            category
          }
          tags {
            id
          }
          videoUrl
          heroImage {
            id
            public_id
            context {
              custom {
                Credits
                alt
                caption
              }
            }
            url
          }
          ingress {
            ingress
            childMdx {
              timeToRead
            }
          }
          body {
            childMdx {
              timeToRead
              body
            }
          }
          author {
            name
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const sortedArticles = SortByDate(data.allContentfulArticle.nodes)

  // Show the top latest articles
  const topFeaturedArticles = sortedArticles.slice(0, 3)
  const relatedArticlesProps: RelatedSectionProps = {
    title: "Eiernytt",
    path: "nb/minside/eiernytt",
    data: topFeaturedArticles,
    headerUrl: "/minside/eiernytt",
  }
  return <MSRelatedSection {...relatedArticlesProps} />
}

export default RelatedContentFrontMS
